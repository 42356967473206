/* This stylesheet generated by Transfonter (https://transfonter.org) on April 2, 2017 6:55 PM */

@font-face {
	font-family: 'Core Rhino 75';
	src: url('../fonts/CoreRhino75Heavy.eot');
	src: url('../fonts/CoreRhino75Heavy.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CoreRhino75Heavy.woff2') format('woff2'),
		url('../fonts/CoreRhino75Heavy.woff') format('woff'),
		url('../fonts/CoreRhino75Heavy.ttf') format('truetype'),
		url('../fonts/CoreRhino75Heavy.svg#CoreRhino75Heavy') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Core Rhino 85';
	src: url('../fonts/CoreRhino85Black-Italic.eot');
	src: url('../fonts/CoreRhino85Black-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CoreRhino85Black-Italic.woff2') format('woff2'),
		url('../fonts/CoreRhino85Black-Italic.woff') format('woff'),
		url('../fonts/CoreRhino85Black-Italic.ttf') format('truetype'),
		url('../fonts/CoreRhino85Black-Italic.svg#CoreRhino85Black-Italic') format('svg');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Core Rhino 55';
	src: url('../fonts/CoreRhino55Medium.eot');
	src: url('../fonts/CoreRhino55Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CoreRhino55Medium.woff2') format('woff2'),
		url('../fonts/CoreRhino55Medium.woff') format('woff'),
		url('../fonts/CoreRhino55Medium.ttf') format('truetype'),
		url('../fonts/CoreRhino55Medium.svg#CoreRhino55Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Core Rhino 55';
	src: url('../fonts/CoreRhino55Medium-Italic.eot');
	src: url('../fonts/CoreRhino55Medium-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CoreRhino55Medium-Italic.woff2') format('woff2'),
		url('../fonts/CoreRhino55Medium-Italic.woff') format('woff'),
		url('../fonts/CoreRhino55Medium-Italic.ttf') format('truetype'),
		url('../fonts/CoreRhino55Medium-Italic.svg#CoreRhino55Medium-Italic') format('svg');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Core Rhino 45';
	src: url('../fonts/CoreRhino45Regular.eot');
	src: url('../fonts/CoreRhino45Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CoreRhino45Regular.woff2') format('woff2'),
		url('../fonts/CoreRhino45Regular.woff') format('woff'),
		url('../fonts/CoreRhino45Regular.ttf') format('truetype'),
		url('../fonts/CoreRhino45Regular.svg#CoreRhino45Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Core Rhino 65';
	src: url('../fonts/CoreRhino65Bold-Italic.eot');
	src: url('../fonts/CoreRhino65Bold-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CoreRhino65Bold-Italic.woff2') format('woff2'),
		url('../fonts/CoreRhino65Bold-Italic.woff') format('woff'),
		url('../fonts/CoreRhino65Bold-Italic.ttf') format('truetype'),
		url('../fonts/CoreRhino65Bold-Italic.svg#CoreRhino65Bold-Italic') format('svg');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Core Rhino 65';
	src: url('../fonts/CoreRhino65Bold.eot');
	src: url('../fonts/CoreRhino65Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CoreRhino65Bold.woff2') format('woff2'),
		url('../fonts/CoreRhino65Bold.woff') format('woff'),
		url('../fonts/CoreRhino65Bold.ttf') format('truetype'),
		url('../fonts/CoreRhino65Bold.svg#CoreRhino65Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Core Rhino 25';
	src: url('../fonts/CoreRhino25Thin.eot');
	src: url('../fonts/CoreRhino25Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CoreRhino25Thin.woff2') format('woff2'),
		url('../fonts/CoreRhino25Thin.woff') format('woff'),
		url('../fonts/CoreRhino25Thin.ttf') format('truetype'),
		url('../fonts/CoreRhino25Thin.svg#CoreRhino25Thin') format('svg');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Core Rhino 75';
	src: url('../fonts/CoreRhino75Heavy-Italic.eot');
	src: url('../fonts/CoreRhino75Heavy-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CoreRhino75Heavy-Italic.woff2') format('woff2'),
		url('../fonts/CoreRhino75Heavy-Italic.woff') format('woff'),
		url('../fonts/CoreRhino75Heavy-Italic.ttf') format('truetype'),
		url('../fonts/CoreRhino75Heavy-Italic.svg#CoreRhino75Heavy-Italic') format('svg');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Core Rhino 85';
	src: url('../fonts/CoreRhino85Black.eot');
	src: url('../fonts/CoreRhino85Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CoreRhino85Black.woff2') format('woff2'),
		url('../fonts/CoreRhino85Black.woff') format('woff'),
		url('../fonts/CoreRhino85Black.ttf') format('truetype'),
		url('../fonts/CoreRhino85Black.svg#CoreRhino85Black') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Core Rhino 45';
	src: url('../fonts/CoreRhino45Regular-Italic.eot');
	src: url('../fonts/CoreRhino45Regular-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CoreRhino45Regular-Italic.woff2') format('woff2'),
		url('../fonts/CoreRhino45Regular-Italic.woff') format('woff'),
		url('../fonts/CoreRhino45Regular-Italic.ttf') format('truetype'),
		url('../fonts/CoreRhino45Regular-Italic.svg#CoreRhino45Regular-Italic') format('svg');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Core Rhino 35';
	src: url('../fonts/CoreRhino35Light.eot');
	src: url('../fonts/CoreRhino35Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CoreRhino35Light.woff2') format('woff2'),
		url('../fonts/CoreRhino35Light.woff') format('woff'),
		url('../fonts/CoreRhino35Light.ttf') format('truetype'),
		url('../fonts/CoreRhino35Light.svg#CoreRhino35Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Core Rhino 25';
	src: url('../fonts/CoreRhino25Thin-Italic.eot');
	src: url('../fonts/CoreRhino25Thin-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CoreRhino25Thin-Italic.woff2') format('woff2'),
		url('../fonts/CoreRhino25Thin-Italic.woff') format('woff'),
		url('../fonts/CoreRhino25Thin-Italic.ttf') format('truetype'),
		url('../fonts/CoreRhino25Thin-Italic.svg#CoreRhino25Thin-Italic') format('svg');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Core Rhino 35';
	src: url('../fonts/CoreRhino35Light-Italic.eot');
	src: url('../fonts/CoreRhino35Light-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CoreRhino35Light-Italic.woff2') format('woff2'),
		url('../fonts/CoreRhino35Light-Italic.woff') format('woff'),
		url('../fonts/CoreRhino35Light-Italic.ttf') format('truetype'),
		url('../fonts/CoreRhino35Light-Italic.svg#CoreRhino35Light-Italic') format('svg');
	font-weight: 300;
	font-style: italic;
}
